import React from "react";
import h2p from 'html2plaintext';
import Link from "gatsby-link";
import { graphql } from 'gatsby';
import buildCategoryPath from "../utils/category-path.js";
import TemplateWrapper from "../components/template-wrapper";

export default function sciSocIndex({ data }) {
  let { nodes: posts } = data.allMarkdownRemark;
  console.log("Posts length: " + posts.length);
  const category = "science-society";
  const site = "https://blogs.cybersym.com";
  const share = {
    url: site + "/" + category,
    title: "CyberSym Science & Society Blog",
    excerpt: "The CyberSym Science & Society blog explores issues involving the impact of science on society and vice-versa",
    realContent: true
  };
  const accumulation = (
    posts.reduce( ( accum, post ) => {
      if (accum === undefined)
        accum = "";
      if (post === undefined)
        return accum;

      console.log(post.frontmatter.id);  

      const pExcerpt = " " + h2p(post.excerpt);
      const ptTitle = " " + h2p(post.frontmatter.title);
      const pTags = " " + post.frontmatter.tags;

      return accum += pExcerpt + ptTitle + pTags;
    }, "")
  );

  return (
    <div className="palette--cst-site"> 
    <TemplateWrapper headerImages={data.allFile.nodes} accumulation = {accumulation} category={category} share={share} >   
      {posts
        .filter(post => post.frontmatter.title.length > 0)
        .map( ( post ) => {
          return (
            <div className="blog-post-preview" key={post.id}>
              <h2>
              <Link to={buildCategoryPath(category, post.fields.link)} dangerouslySetInnerHTML={{__html: post.frontmatter.title}}/>
              </h2>
              <h4>Written by {post.frontmatter.author} on {post.frontmatter.date}</h4>
              <h4>Tags: {post.frontmatter.tags}  
              {/* {
                post.frontmatter.tags && 
                post.frontmatter.tags
                .map( tag => (
                  <span key={tag.id}>
                    { counter++ > 0 ? `, ` : ' '}
                    {tag.name}
                  </span>
              ))} */}
              </h4>  
              <div dangerouslySetInnerHTML={{__html: post.excerpt}} />
              <div className="read-more" ><Link to={buildCategoryPath(category, post.fields.link)}>Read More</Link></div>
            </div>
          );
        })}
    </TemplateWrapper>
    </div>
  );
}

export const pageQuery = graphql`
  query sciSocIndexQuery {
    allMarkdownRemark(
      filter: { frontmatter: { categories: { eq: "science-society" } } },
      sort: { order: DESC, fields: [frontmatter___date] }) {
      nodes {
        id
        excerpt(pruneLength: 1500, format: HTML)
        fields {
          link
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          author
          categories
          tags
        }
      }
    }
    allFile(filter: { sourceInstanceName: { eq: "images" }, 
    name: { regex: "/science-society/" } }) 
    {       
      nodes {
        id
        relativePath
        extension
        name
        childImageSharp {
          gatsbyImageData( layout: FULL_WIDTH )
        }
      } 
    }
  }
`;
